import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import DateFoodTruckEvents from "./components/DateFoodTruckEvents"

function App() {

  const [data, setData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    fetch(process.env.REACT_APP_FOODTRUCKS_API_URL)
      .then(res => res.json())
      .then(
        (result) => {
          setData(result)
          setIsLoaded(true)
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {

        }
      )
  }, [])

  const [filter, setFilter] = useState('Today');

  var content;
  if (isLoaded)
  {
    if (filter === 'Today')
    {
      content = <DateFoodTruckEvents data={data.today}/>
    }
    else if (filter === 'Tomorrow')
    {
      content = <DateFoodTruckEvents data={data.tomorrow}/>
    }
    else
    {
      content = data.this_week.map(date => (
        <DateFoodTruckEvents data={date} key={date.date}/>
      ));
    }
  } else {
    content = <p>Loading</p>
  }

  return (
    <>
    <Navbar bg="dark" variant="dark" expand="lg">
      <Container>
        <Navbar.Brand href="#home">
          <img
            alt=""
            src="/logo_white.svg"
            width="30"
            height="30"
            className="d-inline-block align-top"
          />{' '}
          FOODTRUCKS
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto" onSelect={(key, event) => {setFilter(key)}}>
          <Nav.Link className={('Today' === filter) && "active"} eventKey='Today'>Today</Nav.Link>
          <Nav.Link className={('Tomorrow' === filter) && "active"} eventKey='Tomorrow'>Tomorrow</Nav.Link>
          <Nav.Link className={('This Week' === filter) && "active"} eventKey='This Week'>This Week</Nav.Link>
        </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    <Navbar bg="info" variant="dark" expand="lg">
      <Container>
        <p className="my-0"><strong>{filter}</strong></p>
      </Container>
    </Navbar>
    <Container>
      {content}
    </Container>
    </>
  );
}

export default App;

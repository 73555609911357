import React from "react";
import ListGroup from 'react-bootstrap/ListGroup'
import FoodTruckEvent from "./FoodTruckEvent"

export default function DateFoodTruckEvents(props) {

    var content;
    if (props.data.trucks.length === 0) {
        content = <><p><img
        alt=""
        src="/logo_sad.svg"
        width="30"
        height="30"
        className="d-inline-block align-top mx-2"
      />No trucks found!</p></>
    } else {
        const foodTruckEvents = props.data.trucks.map((foodtruckevent) => (
        <FoodTruckEvent food={foodtruckevent.food} location={foodtruckevent.location} hours={foodtruckevent.hours} order_url={foodtruckevent.order_url} key={foodtruckevent.event_id}/>));
        content = <ListGroup variant="flush">
                    {foodTruckEvents}
                  </ListGroup>
    }
    
    return (
    <>
    <h6 className="my-2">{props.data.date}</h6>
    <hr></hr>
        {content}
    </>
    );
}
import React from "react";
import ListGroup from 'react-bootstrap/ListGroup'
export default function FoodTruckEvent(props) {
    return (
    <ListGroup.Item>
    <h5 className="truck-title">{props.food}</h5>
    <p className="my-0">{props.location}</p><p className="my-1"><em>{props.hours}</em></p>
    <a href={props.order_url} target="_blank" rel="noreferrer"><h6 className="order-title">Order Online</h6></a>
    </ListGroup.Item>
    );
}